import React from 'react'
import Hero from './static-hero.styles'

const BingHero = ({ imageUrl, title, subtitle }) => (
  <Hero background={imageUrl}>
    <Hero.contentContainer>
      <Hero.title>{title}</Hero.title>
      {subtitle && <Hero.subtitle>{subtitle}</Hero.subtitle>}
    </Hero.contentContainer>
  </Hero>
)

export default BingHero
