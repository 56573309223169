import styled from 'styled-components'
import theme, { media } from '../../helpers/theme'

const Hero = styled.div`
  background-image: linear-gradient(
      to bottom,
      rgba(25, 28, 38, 0.7) 0%,
      rgba(25, 28, 38, 0.7) 100%
    ),
    url(${(props) => props.background});
  width: 100%;
  height: 380px;
  padding: ${theme.space[16]}px 0;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: 50% -330px;
  background-size: 100% auto;
  ${media('sm')} {
    height: 340px;
  }
`

Hero.contentContainer = styled.div`
  padding-right: ${theme.space[4]}px;
  padding-left: ${theme.space[4]}px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  ${media(768)} {
    width: 750px;
  }
  ${media(992)} {
    width: 970px;
  }
  ${media(1200)} {
    width: 1170px;
  }
`

Hero.title = styled.span`
  text-transform: capitalize;
  font-size: ${theme.font['3xl']};
  line-height: ${theme.space[12]}px;
  color: ${theme.colors.font.white};
  text-align: center;
  font-weight: 300;

  ${media('xs')} {
    font-size: ${theme.font['4xl']};
  }

  ${media('sm')} {
    line-height: ${theme.space[16]}px;
    font-size: ${theme.font['5xl']};
  }
`

Hero.subtitle = styled.span`
  font-size: ${theme.font['sm']};
  text-align: center;
  color: ${theme.colors.font.white};

  ${media('xs')} {
    font-size: ${theme.font['base']};
  }
  ${media('sm')} {
    font-size: ${theme.font['lg']};
  }
`

export default Hero
