import React from 'react'
import Policy from './bing-content.styles'

const BingContent = () => {
  return (
    <Policy>
      <Policy.title>
        Find new customers and expand your business <br /> Get $100 in Bing Ads
        credit with applicable paid plans.*
      </Policy.title>
      <Policy.subtitle>
        Your purchase entitles you to $100 in Bing Ads credit to advertise to
        your customers searching on the Yahoo Bing Network. Sign up today and
        draw more customers to your website with ads on the Yahoo Bing Network.
        See Advertisers Term and Conditions for more details at sign-up.
      </Policy.subtitle>
      <Policy.paragraph>
        *Offer Details: Offer expires January 15, 2018. Get $100 in Bing Ads
        credits with your paid hosting plan and for signing up as a new Bing Ads
        customer. A new Bing Ads customer is one that has not advertised on Bing
        Ads before. The Bing Ads customer will receive a promotional code to
        redeem credits. Limit one (1) promotion code per new Bing Ads customer.
        Promotional code must be redeemed within thirty (30) days of Bing Ads
        new customer creation. Ad campaign costs accrued before receiving a
        credit, as well as costs accrued after all credits are used, will be
        charged to the payment method associated with the Bing Ads account. Ads
        will continue to run and accrue costs after any promotional credit has
        been used up. Any portion of the credit not used within ninety (90) days
        of credit redemption will expire and can't be carried over even if the
        Bing Ads Customer switches their payment method. Offer valid only to
        residents of the United States and Canada. In order for the offer to be
        valid, a form of payment must be entered into your account prior to
        redemption of the promotional code and prior to your account going live
        to take advantage of the credit. This offer may not be combined with any
        other offer, promotional code coupon or discount, separated, redeemed
        for cash, transferred, sold, or bartered. Microsoft, in its sole
        discretion, can close your account for any suspected coupon abuse. See
        Bing Ads Terms and Conditions for more details at sign-up. Other terms
        and conditions may apply.
      </Policy.paragraph>
    </Policy>
  )
}

export default BingContent
