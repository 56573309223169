import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import herobg from '../images/bing-feature.jpeg'
import StaticHero from '../components/static-hero'
import BingContent from '../components/bing-content'

const Bing = () => {
  return (
    <Layout>
      <SEO title='Bing Ad Credits' pageType='home' flowType='none' />
      <StaticHero imageUrl={herobg} title={'Bing Ad Credits'} />
      <BingContent />
    </Layout>
  )
}

export default Bing
